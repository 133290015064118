import React, { useContext, useEffect, useState } from "react";
import "./StudentGroupsSection.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsisVertical,
  faPenToSquare,
  faPlus,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import ButtonListPopup from "../../Components/ButtonListPopup/ButtonListPopup";
import ModifyGroupModal from "../../Components/Modals/ModifyGroupModal/ModifyGroupModal";
import ConfirmationModal from "../../Components/Modals/ConfirmationModal/ConfirmationModal";
import { OSCEAdminContext } from "../..";

const StudentGroupsSection = () => {
  const [showGroupOptionsPopup, setShowGroupOptionsPopup] = useState(false);
  const [selectedGroupIndex, setSelectedGroupIndex] = useState(null);
  const [showEditGroupModal, setShowEditGroupModal] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const {
    userData,
    mousePosition,
    route,
    updateUserData,
    displayNotification,
    handleExpiredUserSession,
  } = useContext(OSCEAdminContext);

  const handleButtonListPopupClick = (index) => {
    setSelectedGroupIndex(index);
    setShowGroupOptionsPopup(true);
  };

  const handleEditGroupClick = () => {
    setShowGroupOptionsPopup(false);
    setShowEditGroupModal(true);
  };

  const handleAddGroup = () => {
    setSelectedGroupIndex(null);
    setShowEditGroupModal(true);
  };

  const handleDeleteGroupClick = () => {
    setShowGroupOptionsPopup(false);
    setShowConfirmDelete(true);
  };

  const handleDeleteGroup = async () => {
    await fetchModifyGroups(
      "delete",
      "",
      userData.Groups[selectedGroupIndex].groupId
    );
    setShowConfirmDelete(false);
  };

  const handleUpdateStudentGroups = (studentsModified) => {
    let studentIds = [];
    let groupId = studentsModified[0].GroupId;
    let groupName = studentsModified[0].GroupName;

    studentsModified.forEach((student) => {
      studentIds.push(student.StudentId);
    });

    const students = userData.Students.map((student) => {
      if (studentIds.includes(student.CustomerId)) {
        student.GroupId = groupId;
        student.GroupName = groupName;
      }
      return student;
    });

    updateUserData({
      ...userData,
      Students: students,
    });
  };

  const fetchModifyGroups = async (action, groupName, groupId) => {
    let body = null;
    if (action === "add") {
      body = {
        GroupId: "",
        GroupName: groupName,
        DeleteGroupId: "",
      };
    } else if (action === "update") {
      body = {
        GroupId: groupId,
        GroupName: groupName,
        DeleteGroupId: "",
      };
    } else {
      body = {
        GroupId: groupId,
        GroupName: "",
        DeleteGroupId: groupId,
      };
    }

    return await fetch(`${route}/addeditgroup.webapi`, {
      method: "POST",
      headers: {
        Token: userData.Token,
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else if (response.status === 401) {
          handleExpiredUserSession();
          return false;
        } else {
          throw new Error(`${response.status} - ${response.statusText}`);
        }
      })
      .then((data) => {
        if (!data) return false;
        if (action === "add") {
          displayNotification("Group added successfully.");
        } else if (action === "update") {
          displayNotification("Group updated successfully.");
          if (data.studentsChanged.length > 0)
            handleUpdateStudentGroups(data.studentsChanged);
        } else {
          displayNotification("Group deleted successfully.");
          if (data.studentsDeleted.length > 0)
            handleUpdateStudentGroups(data.studentsDeleted);
        }
        updateUserData({
          ...userData,
          Groups: data.groups,
        });
        return true;
      })
      .catch((error) => {
        console.log(error);
        displayNotification("An error occurred. Please try again.");
        return false;
      });
  };

  return (
    <>
      <div className="adminPortalMySchoolGroupsWrapper">
        <h2 className="adminPortalMySchoolGroupTitleText">Student Groups</h2>
        <button
          className="adminPortalMySchoolAddGroupButton"
          onClick={handleAddGroup}
        >
          <FontAwesomeIcon icon={faPlus} />
          Add Group
        </button>
        <div className="adminPortalMySchoolGroupsListContainer">
          <div className="adminPortalMySchoolGroupsListWrapper">
            {userData.Groups.map((group, index) => (
              <div
                className="adminPortalMySchoolGroupListItem"
                key={`group list item - ${group.groupId} - ${group.GroupName}`}
              >
                <p className="adminPortalMySchoolGroupListItemText">
                  {group.groupId} - {group.GroupName}
                </p>
                <button
                  className="adminPortalMySchoolGroupOptionsButton"
                  onClick={() => handleButtonListPopupClick(index)}
                >
                  <FontAwesomeIcon icon={faEllipsisVertical} />
                </button>
              </div>
            ))}
          </div>
        </div>

        {showGroupOptionsPopup && (
          <ButtonListPopup
            mousePos={{
              x: mousePosition.x,
              y: mousePosition.y,
            }}
            setShowButtonListPopup={setShowGroupOptionsPopup}
            showButtonListPopup={showGroupOptionsPopup}
          >
            <button onClick={handleEditGroupClick}>
              <FontAwesomeIcon icon={faPenToSquare} />
              Edit Group
            </button>
            <button onClick={handleDeleteGroupClick}>
              <FontAwesomeIcon icon={faTrashCan} />
              Delete Group
            </button>
          </ButtonListPopup>
        )}
      </div>
      {showEditGroupModal && (
        <ModifyGroupModal
          setShowEditGroupModal={setShowEditGroupModal}
          selectedGroup={
            selectedGroupIndex !== null
              ? userData.Groups[selectedGroupIndex]
              : null
          }
          fetchModifyGroups={fetchModifyGroups}
        />
      )}
      {showConfirmDelete && (
        <ConfirmationModal
          header={`Delete Group ${userData.Groups[selectedGroupIndex]?.groupId}?`}
          text={`Are you sure you want to delete group ${userData.Groups[selectedGroupIndex]?.groupId} - ${userData.Groups[selectedGroupIndex]?.GroupName}? This action cannot be undone.`}
          closeModal={() => setShowConfirmDelete(false)}
          confirmFunction={handleDeleteGroup}
        />
      )}
    </>
  );
};

export default StudentGroupsSection;
