import React, { useContext, useState } from "react";
import { OSCEAdminContext } from "../..";
import MDSpinner from "react-md-spinner";
import AddGroupModal from "./ModifyGroupModal/ModifyGroupModal";

const GroupListModal = ({
  setShowModal,
  confirmSelection,
  subHeader,
  showAddGroupButton,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [fetchOutstanding, setFetchOutstanding] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [showAddGroupModal, setShowAddGroupModal] = useState(false);
  const {
    userData,
    updateUserData,
    displayNotification,
    route,
    handleExpiredUserSession,
  } = useContext(OSCEAdminContext);

  const filteredGroups = userData.Groups.filter((group) =>
    `${group.groupId} - ${group.GroupName}`
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  );

  const handleConfirmSelection = async () => {
    setFetchOutstanding(true);
    const success = await confirmSelection(selectedGroup);
    if (success) setShowModal(false);
    else setFetchOutstanding(false);
  };

  const fetchAddGroup = async (_, groupName) => {
    let body = {
      GroupId: "",
      GroupName: groupName,
      DeleteGroupId: "",
    };

    return await fetch(`${route}/addeditgroup.webapi`, {
      method: "POST",
      headers: {
        Token: userData.Token,
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else if (response.status === 401) {
          handleExpiredUserSession();
          return false;
        } else {
          throw new Error(`${response.status} - ${response.statusText}`);
        }
      })
      .then((data) => {
        if (!data) return false;
        displayNotification("Group added successfully.");
        updateUserData({
          ...userData,
          Groups: data.groups,
        });
        return true;
      })
      .catch((error) => {
        console.log(error);
        displayNotification("An error occurred. Please try again.");
        return false;
      });
  };

  return (
    <>
      <div className="modalPageWrapper">
        <div className="modalBackdrop" />
        <div className="modalContainer">
          <h2 className="modalHeader">Select Group</h2>
          <p className="modalSubHeader">{subHeader}</p>
          <div className="modalInputWrapper">
            <label htmlFor="studentSearch" className="modalInputLabel">
              Search Groups
            </label>
            <input
              type="text"
              id="studentSearch"
              className="modalInput"
              placeholder={`Search for groups...`}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <div className="modalInputWrapper">
            <label className="modalInputLabel">
              Groups ({userData.Groups.length})
            </label>
            <div
              className="addAccountsModalAccountListWrapper"
              style={{ height: "fit-content", maxHeight: 250 }}
            >
              {filteredGroups.map((group) => (
                <button
                  className={`addAccountsModalAccountListItem ${
                    group.groupId === selectedGroup?.groupId
                      ? "addAccountsModalAccountListItemSelected"
                      : ""
                  }`}
                  key={group.groupId}
                  onClick={() => setSelectedGroup(group)}
                >
                  {`${group.groupId}${
                    group.GroupName ? ` - ${group.GroupName}` : ""
                  }`}
                </button>
              ))}
              {filteredGroups.length === 0 && (
                <p className="addAccountsModalNoAccountsMessage">
                  No groups match search "{searchTerm}"
                </p>
              )}
              {showAddGroupButton && (
                <button
                  className="modalStudentListAddButton"
                  onClick={() => setShowAddGroupModal(true)}
                >
                  + Add Group
                </button>
              )}
            </div>
          </div>
          {fetchOutstanding ? (
            <div className="modalSpinnerWrapper">
              <MDSpinner size={35} singleColor="#0B335D" />
            </div>
          ) : (
            <div className="modalButtonsWrapper">
              <button
                className="modalCancelButton"
                onClick={() => setShowModal(false)}
              >
                Cancel
              </button>
              <button
                className="modalConfirmButton"
                onClick={handleConfirmSelection}
              >
                Confirm
              </button>
            </div>
          )}
        </div>
      </div>
      {showAddGroupModal && (
        <AddGroupModal
          fetchModifyGroups={fetchAddGroup}
          setShowEditGroupModal={setShowAddGroupModal}
        />
      )}
    </>
  );
};

export default GroupListModal;
