import React, { useState } from "react";
import LoginPage from "../NewLogin/LoginPage";
import OSCEStudent from "./StudentPortal";
import OSCEAdmin from "./AdminPortal";
import PreceptorPortal from "./PreceptorPortal/PreceptorPortal";

const WEB_SERVER_ROUTE = "https://osce.ccscases.com";
const OSCE = () => {
  const [application, setApplication] = useState(null);
  const [userData, setUserData] = useState(null);

  const handleLogin = (userAccountDetails, isStudent) => {
    console.log(userAccountDetails);

    if (!isStudent && userAccountDetails.Admin) {
      // Add the GroupName to each student
      if (!userAccountDetails.Students) {
        userAccountDetails.Students = [];
      }
      if (!userAccountDetails.Groups) {
        userAccountDetails.Groups = [];
      }
      if (!userAccountDetails.Preceptors) {
        userAccountDetails.Preceptors = [];
      }
      userAccountDetails.Students = userAccountDetails.Students.map(
        (student) => {
          const group = userAccountDetails.Groups.find(
            (group) => group.groupId === student.GroupId
          );
          if (group) student.GroupName = group.GroupName;
          return student;
        }
      );

      const studentGrades = {};
      userAccountDetails.StudentGrades.forEach((examInstance) => {
        if (examInstance.OsceCompleted) {
          if (!studentGrades[examInstance.StudentId]) {
            studentGrades[examInstance.StudentId] = {
              completedExams: [],
              casesCompletedCount: 0,
            };
          }
          studentGrades[examInstance.StudentId].completedExams.push(
            examInstance
          );
          studentGrades[examInstance.StudentId].casesCompletedCount +=
            examInstance.OsceIndGrade.length;
        }
      });

      userAccountDetails.Students = userAccountDetails.Students.map(
        (student) => {
          student.StudentGrades = studentGrades[student.CustomerId] || [];
          return student;
        }
      );

      setApplication("Admin");
    } else if (userAccountDetails.CustType === "Preceptor") {
      // Add the GroupName to each student
      if (userAccountDetails.StudentInfoForPreceptors) {
        userAccountDetails.StudentInfoForPreceptors =
          userAccountDetails.StudentInfoForPreceptors.map((student) => {
            const group = userAccountDetails.Groups.find(
              (group) => group.groupId === student.GroupId
            );
            if (group) student.GroupName = group.GroupName;
            return student;
          });
        userAccountDetails.Students =
          userAccountDetails.StudentInfoForPreceptors;
        delete userAccountDetails.StudentInfoForPreceptors;
        // Map StudentId to CustomerId
        for (let i = 0; i < userAccountDetails.Students.length; i++) {
          userAccountDetails.Students[i].CustomerId =
            userAccountDetails.Students[i].StudentId;
          delete userAccountDetails.Students[i].StudentId;
        }
      }
      setApplication("Preceptor");
    } else {
      // If IndividualCases.CaseId array is the same as SpecificCasesOut.CaseId array
      // then the student is taking the same osce and we should start them at the most recent not completed in IndividualCases array
      // Otherwise we should start them at the first case in the SpecificCasesOut array

      if (userAccountDetails.LastOsceAttempt) {
        const individualCasesIds =
          userAccountDetails.LastOsceAttempt.IndividualCases.map(
            (individualCase) => individualCase.CaseId
          );
        const specificCasesIds = userAccountDetails.SpecificCasesOut.map(
          (specificCase) => specificCase.CaseId
        );
        const isTakingSameOsce =
          individualCasesIds.every(
            (caseId, index) => specificCasesIds[index] === caseId
          ) && individualCasesIds.length === specificCasesIds.length;
        let casesArray = [];

        if (isTakingSameOsce) {
          console.log("Taking same osce");
          casesArray = userAccountDetails.LastOsceAttempt.IndividualCases.map(
            (individualCase) => individualCase.CaseId
          );
          userAccountDetails.initialCaseIndex =
            userAccountDetails.LastOsceAttempt.IndividualCases.findIndex(
              (individualCase) => !individualCase.CaseCompleted
            );
          userAccountDetails.OsceGuid =
            userAccountDetails.LastOsceAttempt.OsceGuid;
        } else {
          console.log("Taking different osce");
          casesArray = userAccountDetails.SpecificCasesOut.map(
            (specificCase) => specificCase.CaseId
          );
          userAccountDetails.initialCaseIndex = 0;
        }
        userAccountDetails.CasesArray = casesArray;
      } else {
        userAccountDetails.CasesArray = userAccountDetails.SpecificCasesOut.map(
          (specificCase) => specificCase.CaseId
        );
        userAccountDetails.initialCaseIndex = 0;
      }

      setApplication("Student");
    }

    setUserData(userAccountDetails);
  };

  const updateUserData = (newUserData) => {
    setUserData(newUserData);
  };

  if (!userData) {
    return (
      <div style={{ height: "100vh" }}>
        <LoginPage
          application="OSCE"
          handleLogin={handleLogin}
          route={WEB_SERVER_ROUTE}
          primaryColor={null}
          selectedTheme={null}
        />
      </div>
    );
  }

  let contentToRender = null;

  switch (application) {
    case "Student":
      contentToRender = (
        <OSCEStudent userData={userData} updateUserData={updateUserData} />
      );
      break;
    case "Admin":
      contentToRender = (
        <OSCEAdmin
          userData={userData}
          route={WEB_SERVER_ROUTE}
          updateUserData={updateUserData}
        />
      );
      break;
    case "Preceptor":
      contentToRender = (
        <PreceptorPortal userData={userData} route={WEB_SERVER_ROUTE} />
      );
      break;
    default:
      contentToRender = (
        <div
          style={{
            maxWidth: 500,
            width: "90%",
            margin: "auto",
            paddingTop: 200,
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
          Application not found, please refresh the page and try logging in
          again. If this issue persists, please contact us.
        </div>
      );
      break;
  }

  return contentToRender;
};

export default OSCE;
