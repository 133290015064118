import React, { useContext, useEffect, useState } from "react";
import "./IndividualStudentGrades.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserDoctor } from "@fortawesome/free-solid-svg-icons";
import { OSCEAdminContext } from "..";
import MDSpinner from "react-md-spinner";
import ListItemsSelectorModal from "../Components/Modals/ListItemsSelectorModal/ListItemsSelectorModal";
import CaseDataComponent from "./Components/CaseDataComponent";
import ExamDataOverviewComponent from "./Components/ExamDataOverviewComponent";
import formatTimeString from "../../../Functions/FormatTimeString";
import formatDateString from "../../../Functions/FormatDateString";

const getCaseCountFromExamArray = (examArray) => {
  let caseCount = 0;
  examArray.forEach((exam) => {
    caseCount += exam.OsceIndGrade.length;
  });
  return caseCount;
};

const IndividualStudentGrades = () => {
  const {
    route,
    userData,
    handleExpiredUserSession,
    displayNotification,
    prevPageProps,
    handleChangePage,
  } = useContext(OSCEAdminContext);
  const [studentGradeData, setStudentGradeData] = useState(null);
  const [selectedExamIndex, setSelectedExamIndex] = useState(0);
  const [pageError, setPageError] = useState(null);
  const [showChangeExamPopup, setShowChangeExamPopup] = useState(false);

  const getStudentName = () => {
    const student = userData.Students.find(
      (student) => student.CustomerId === prevPageProps.studentId
    );
    if (!student) {
      return "Invalid Student Id";
    }
    return `${student.CustomerFirstName} ${student.CustomerMiddleName} ${student.CustomerLastName}`;
  };

  const fetchStudentGradeData = async () => {
    if (!prevPageProps?.studentId) {
      displayNotification("No student ID provided.");
      return;
    }

    await fetch(`${route}/oscestudentgrade.webapi`, {
      method: "POST",
      headers: {
        Token: userData.Token,
      },
      body: JSON.stringify({
        StudentId: prevPageProps.studentId,
        // StudentId: 2,
      }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else if (response.status === 401) {
          handleExpiredUserSession();
          return false;
        } else {
          throw new Error("Failed to fetch grading data.");
        }
      })
      .then((data) => {
        if (!data) return;

        const completedExams = data.filter((exam) => exam.OsceCompleted);

        if (data.length === 0) {
          setPageError(
            `No exam data found for studentId: ${prevPageProps.studentId}`
          );
          return;
        }

        const gradesData = {
          studentName: getStudentName(),
          completedExamCount: completedExams.length,
          completedCaseCount: getCaseCountFromExamArray(completedExams),
          examArray: completedExams,
        };
        setStudentGradeData(gradesData);
      })
      .catch((error) => {
        displayNotification(error.message);
      });
  };

  useEffect(() => {
    fetchStudentGradeData();
  }, [prevPageProps?.studentId]);

  const handleBackButtonClick = () => {
    if (prevPageProps.prevPage === "Student") {
      // Navigate to "StudentProfilePage" component
      handleChangePage(prevPageProps.prevPage, {
        studentId: prevPageProps.studentId,
      });
    } else if (prevPageProps.prevPage === "Grades") {
      // Navigate to "GradesPage" component
      handleChangePage(prevPageProps.prevPage, {
        initialTab: prevPageProps.initialTab,
      });
    } else if (prevPageProps.prevPage === "home") {
      handleChangePage("home");
    }
  };

  const handleChangeSelectedExam = (selectedExamArray) => {
    const examIndex = studentGradeData.examArray.findIndex(
      (exam) => exam.OsceGuid === selectedExamArray[0]
    );
    setSelectedExamIndex(examIndex);
    setShowChangeExamPopup(false);
  };

  if (pageError) {
    return (
      <div className="adminIndividualStudentGradesLoaderWrapper">
        <p>{pageError}</p>
        <button
          className="tertiaryButton"
          style={{ padding: "15px 30px" }}
          onClick={handleBackButtonClick}
        >
          {prevPageProps?.backButtonText || "Back to Previous Page"}
        </button>
      </div>
    );
  }

  if (!studentGradeData) {
    return (
      <div className="adminIndividualStudentGradesLoaderWrapper">
        <p>Loading Student Grades</p>
        <MDSpinner size={35} singleColor="#2c3e50" />
      </div>
    );
  }

  if (studentGradeData.examArray.length === 0) {
    return (
      <div className="adminIndividualStudentGradesLoaderWrapper">
        <p>No completed exam data found for student.</p>
        <button
          className="tertiaryButton"
          style={{ padding: "15px 30px" }}
          onClick={handleBackButtonClick}
        >
          {prevPageProps?.backButtonText || "Back to Previous Page"}
        </button>
      </div>
    );
  }

  const { studentName, completedExamCount, completedCaseCount, examArray } =
    studentGradeData;
  const selectedExam = examArray[selectedExamIndex];
  const { OsceIndGrade: casesArray } = selectedExam;

  return (
    <div className="adminIndividualStudentGradesPageWrapper">
      <button
        className="tertiaryButton"
        style={{ padding: "15px 30px" }}
        onClick={handleBackButtonClick}
      >
        {prevPageProps?.backButtonText || "Back to Previous Page"}
      </button>
      <div className="adminIndividualStudentGradesPageHeaderContainer">
        <div className="adminIndividualStudentGradesPageHeaderIconWrapper">
          <FontAwesomeIcon icon={faUserDoctor} />
        </div>
        <div className="adminIndividualStudentGradesPageHeaderTextWrapper">
          <h1>Student Grades</h1>
          <p>{studentName}</p>
        </div>
        <div className="flexBreaker" />
        <div className="adminIndividualStudentGradesPageHeaderValueWrapper">
          <h2>Completed Exams</h2>
          <p>{completedExamCount}</p>
        </div>
        <div className="adminIndividualStudentGradesPageHeaderValueWrapper">
          <h2>Completed Cases</h2>
          <p>{completedCaseCount}</p>
        </div>
      </div>
      <ExamDataOverviewComponent
        selectedExam={selectedExam}
        setShowChangeExamPopup={setShowChangeExamPopup}
      />
      {casesArray.length !== 0 && (
        <CaseDataComponent
          casesArray={casesArray}
          examGuid={selectedExam.OsceGuid}
        />
      )}
      {showChangeExamPopup && (
        <ListItemsSelectorModal
          closeModal={() => setShowChangeExamPopup(false)}
          confirmSelection={handleChangeSelectedExam}
          initialSelectedElements={[examArray[selectedExamIndex].OsceGuid]}
          dataLabel={"Exam"}
          customTitle={"Select Exam"}
          subtitle={`Select a Student Exam`}
          dataArray={examArray.map((exam, index) => ({
            ExamTitle: `Exam ${index + 1} - ${formatDateString(
              exam.TimeOsceEnded,
              userData.TimeZone
            )} ${formatTimeString(exam.TimeOsceEnded, userData.TimeZone)}`,
            OsceGuid: exam.OsceGuid,
          }))}
          dataValueUniqueId={"OsceGuid"}
          dataValuesToDisplay={["ExamTitle"]}
          singleSelect={true}
        />
      )}
    </div>
  );
};

export default IndividualStudentGrades;
