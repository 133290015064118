import React, { useContext, useState } from "react";
import "./CategoriesPage.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import getCategoryIconFromString from "../../Util/getCategoryIconFromString";
import CategoriesLineChart from "./CategoriesLineChart";
import { OSCEAdminContext } from "../..";

const CategoriesPage = () => {
  const { userData } = useContext(OSCEAdminContext);
  const [graphValue, setGraphValue] = useState("Average Grade By Month");

  return (
    <div>
      <div className="adminCategoriesPageHeaderWrapper">
        <h2>Category Grades</h2>
        <select
          value={graphValue}
          onChange={(e) => setGraphValue(e.target.value)}
        >
          <option value="Average Grade By Month">Average Grade By Month</option>
          <option value="Student Count By Month">Student Count By Month</option>
          <option value="Completion Count By Month">
            Completion Count By Month
          </option>
        </select>
      </div>
      <div className="adminCategoriesCardsWrapper">
        {userData.GradesData.categoriesPageData.map((val, index) => (
          <div
            className="adminCategoriesCardContainer"
            id={`categories-card-container-${val.categoryName}`}
            key={val.categoryName}
          >
            <div className="adminCategoriesCardContentWrapper">
              <div className="adminCategoriesCardTitleWrapper">
                <FontAwesomeIcon
                  icon={getCategoryIconFromString(val.categoryName)}
                />
                <h3>{val.categoryName}</h3>
              </div>
              <div className="adminCategoriesCardValueWrapper">
                <h4>Average Score</h4>
                <p>{val.categoryAverageGrade}%</p>
              </div>
              <div className="adminCategoriesCardValueWrapper">
                <h4>All Schools Average Score</h4>
                <p>{val.allSchoolsAverageGrade}%</p>
              </div>
              <div className="adminCategoriesCardValueWrapper">
                <h4>Cases Completed</h4>
                <p>{val.casesCompletedInThisCategory}</p>
              </div>
              {val.graphData.length > 1 ? (
                <div className="adminCategoriesCardValueWrapper">
                  <h4>Average Change Per Month</h4>
                  <FontAwesomeIcon
                    icon={
                      val.averageChangePerMonth >= 0 ? faArrowUp : faArrowDown
                    }
                    style={{
                      color:
                        val.averageChangePerMonth >= 0 ? "#13923e" : "#a90101",
                    }}
                  />
                  <p
                    style={{
                      color:
                        val.averageChangePerMonth >= 0 ? "#13923e" : "#a90101",
                    }}
                  >
                    {val.averageChangePerMonth}%
                  </p>
                </div>
              ) : null}
            </div>
            {val.graphData.length <= 1 ? (
              <div className="adminCategoriesGraphNoDataWrapper">
                <p>Not enough data available for graph. </p>
              </div>
            ) : (
              <div className="adminCategoriesCardGraphWrapper">
                <CategoriesLineChart
                  parentContainerId={`categories-card-container-${val.categoryName}`}
                  graphData={val.graphData.map((data) => {
                    let dataValue = null;

                    switch (graphValue) {
                      case "Average Grade By Month":
                        dataValue = data.averageGrade;
                        break;
                      case "Student Count By Month":
                        dataValue = data.numberOfStudents;
                        break;
                      case "Completion Count By Month":
                        dataValue = data.numberOfCompletions;
                        break;
                      default:
                        dataValue = data.averageGrade;
                        break;
                    }

                    return {
                      date: data.date,
                      value: dataValue,
                    };
                  })}
                  scale={
                    graphValue === "Average Grade By Month" ? "0-100" : "auto"
                  }
                  graphTitle={graphValue}
                />
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default CategoriesPage;
