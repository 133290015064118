import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useState } from "react";
import MDSpinner from "react-md-spinner";
import { OSCEAdminContext } from "../../..";

const AddCreditsModal = ({ closeModal, student, schoolCredits }) => {
  const {
    route,
    userData,
    updateUserData,
    handleExpiredUserSession,
    displayNotification,
  } = useContext(OSCEAdminContext);
  const [fetchOutstanding, setFetchOutstanding] = useState(false);
  const [creditCount, setCreditCount] = useState(student.OsceCredits);

  const fetchAddCredits = async () => {
    setFetchOutstanding(true);
    const creditsToAdd = creditCount - student.OsceCredits;
    await fetch(`${route}/addcredits.webapi`, {
      method: "POST",
      headers: {
        Token: userData.Token,
      },
      body: JSON.stringify([
        {
          CustomerId: student.CustomerId,
          NumberOfCreditsToAdd: creditsToAdd,
        },
      ]),
    })
      .then((res) => {
        if (res.ok) {
          return res.text();
        } else if (res.status === 401) {
          handleExpiredUserSession();
        } else {
          throw new Error("Failed to add credits");
        }
      })
      .then((data) => {
        if (!data) return false;
        const updatedStudents = userData.Students.map((tempStudent) => {
          if (tempStudent.CustomerId === student.CustomerId) {
            return {
              ...tempStudent,
              OsceCredits: creditCount,
            };
          } else {
            return tempStudent;
          }
        });
        updateUserData({
          ...userData,
          Students: updatedStudents,
          OsceCredits: schoolCredits - creditsToAdd,
        });
        displayNotification(
          `${Math.abs(creditsToAdd)} credit${
            Math.abs(creditsToAdd) === 1 ? "" : "s"
          } ${creditsToAdd > 0 ? "added to" : "removed from"} ${
            student.CustomerFirstName
          } ${student.CustomerLastName}`
        );
        closeModal();
      })
      .catch((error) => {
        console.log("Error adding credits: ", error);
        displayNotification("Failed to add credits.");
        setFetchOutstanding(false);
      });
  };

  const incrementCreditCount = () => {
    const maxCredits = schoolCredits + student.OsceCredits;
    if (creditCount >= maxCredits) setCreditCount(maxCredits);
    else setCreditCount(creditCount + 1);
  };

  const decrementCreditCount = () => {
    if (creditCount <= 0) setCreditCount(0);
    else setCreditCount(creditCount - 1);
  };

  const editCreditCount = (e) => {
    // Ensure input is a positive number of 0 or greater
    if (e.target.value.match(/^[0-9]+$/)) {
      const maxCredits = schoolCredits + student.OsceCredits;
      if (parseInt(e.target.value) > maxCredits) {
        setCreditCount(maxCredits);
      } else if (parseInt(e.target.value) < 0) {
        setCreditCount(0);
      } else {
        setCreditCount(parseInt(e.target.value));
      }
    }
    if (e.target.value === "") {
      setCreditCount(0);
    }
  };

  const creditChange = creditCount - student.OsceCredits;

  return (
    <div className="modalPageWrapper">
      <div className="modalBackdrop" />
      <div className="modalContainer">
        <h2 className="modalHeader">Add Credits To Jorge E Santos</h2>
        <div className="modalInputWrapper">
          <label className="modalInputLabel" htmlFor="creditsInput">
            Total Credits
          </label>
          <div className="modalNumberIncrementerWrapper">
            <input
              className="modalInput"
              type="number"
              id="creditsInput"
              value={creditCount}
              onChange={editCreditCount}
            />
            <button onClick={decrementCreditCount} disabled={creditCount <= 0}>
              <FontAwesomeIcon icon={faMinus} />
            </button>
            <button
              onClick={incrementCreditCount}
              disabled={creditCount >= schoolCredits + student.OsceCredits}
            >
              <FontAwesomeIcon icon={faPlus} />
            </button>
          </div>
        </div>
        <div className="modalKeyValueSectionWrapper">
          <div className="modalKeyValueWrapper">
            <p>Credit Change</p>
            <p>
              {creditChange > 0 ? "+" : ""}
              {creditChange}
            </p>
          </div>
          <div className="modalKeyValueWrapper">
            <p>School Current Credits</p>
            <p>{schoolCredits}</p>
          </div>
          <div className="modalKeyValueWrapper">
            <p>School New Credits</p>
            <p>{schoolCredits - (creditCount - student.OsceCredits)}</p>
          </div>
        </div>
        {fetchOutstanding ? (
          <div className="modalSpinnerWrapper">
            <MDSpinner size={40} singleColor="#0B335D" />
          </div>
        ) : (
          <div className="modalButtonsWrapper">
            <button className="modalCancelButton" onClick={closeModal}>
              Cancel
            </button>
            <button
              className="modalConfirmButton"
              onClick={fetchAddCredits}
              disabled={creditChange === 0}
            >
              Save Changes
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default AddCreditsModal;
