import React, { useState } from "react";
import "./PreceptorPortal.css";
import CCSCasesLogo from "../../images/logoCropped.png";
import PreceptorPortalHome from "./PreceptorPortalHome";
import IndividualStudentGrades from "../AdminPortal/IndividualStudentGrades/IndividualStudentGrades";
import { OSCEAdminContext } from "../AdminPortal";
import useMousePosition from "../AdminPortal/Util/useMousePosition";
import { toast } from "react-toastify";
import UnauthenticatedModal from "../../Util/NewUnauthenticatedPopup/NewUnauthenticatedPopup";

const PreceptorPortal = ({ userData, route }) => {
  const [pageDisplayed, setPageDisplayed] = useState("home");
  const mousePosition = useMousePosition();
  const [showUnauthenticatedModal, setShowUnauthenticatedModal] =
    useState(false);
  const [prevPageProps, setPrevPageProps] = useState(null);

  const displayNotification = (message) => {
    toast(message);
  };

  const handleExpiredUserSession = () => {
    setShowUnauthenticatedModal(true);
  };

  const handleChangePage = (page, prevPageProps = null) => {
    setPageDisplayed(page);
    setPrevPageProps(prevPageProps);
  };

  return (
    <div className="preceptorPortalWrapper">
      <div className="preceptorPortalHeaderContainer">
        <div>
          <img src={CCSCasesLogo} alt="CCS Cases Logo" />
          <p>Preceptor</p>
        </div>
        <button onClick={() => window.location.reload()}>Logout</button>
      </div>
      <div className="preceptorPortalBodyWrapper">
        {pageDisplayed === "home" ? (
          <PreceptorPortalHome
            userData={userData}
            handleChangePage={handleChangePage}
            mousePosition={mousePosition}
          />
        ) : (
          <OSCEAdminContext.Provider
            value={{
              userData,
              route,
              updateUserData: () => null,
              mousePosition,
              displayNotification,
              handleModifyUsersExamRules: () => null,
              handleExpiredUserSession,
              prevPageProps,
              handleChangePage,
            }}
          >
            <IndividualStudentGrades />
          </OSCEAdminContext.Provider>
        )}
      </div>
      {showUnauthenticatedModal && (
        <UnauthenticatedModal hideTOSBullet={true} />
      )}
    </div>
  );
};

export default PreceptorPortal;
