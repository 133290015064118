import React, { useState } from "react";
import "./ExamTransitionPage.css";
import CCSCasesLogo from "../../images/logoCropped.png";
import ExamTimer from "./ExamTimer";
import MDSpinner from "react-md-spinner";
import ExamProgressModal from "./ExamProgressModal/ExamProgressModal";

const ExamTransitionPage = ({
  breakTimePassed,
  setBreakTimePassed,
  handleStartExam,
  userData,
  startingExam,
  currentCaseIndex,
  loadingCaseData,
}) => {
  const [showExamProgressModal, setShowExamProgressModal] = useState(false);

  const isFirstCase = currentCaseIndex === 0;
  const isExamFinished = currentCaseIndex === userData.CasesArray.length;

  return (
    <div
      className="examTransitionContainer"
      style={{
        backgroundImage: `url('https://osce.ccscases.com/logos/${userData.SchoolBreakBackgroundImage}')`,
      }}
    >
      <div className="examTransitionBackgroundOverlay" />
      {loadingCaseData ? (
        <div className="examTransitionContentContainer">
          <div
            style={{
              display: "grid",
              justifyItems: "center",
              alignItems: "center",
              gap: 12,
              padding: "50px 0px",
            }}
          >
            <MDSpinner size={40} singleColor="#0b335d" />
            <p style={{ fontWeight: "bold", margin: 0 }}>Loading Exam Data</p>
          </div>
        </div>
      ) : (
        <div className="examTransitionContentContainer">
          {!isExamFinished ? (
            <div className="examTransitionContentWrapper">
              <h1 className="examTransitionContentTitle">
                {userData.SchoolName}
              </h1>
              {currentCaseIndex === 0 ? (
                <p className="examTransitionContenParagraph">
                  Welcome to the {userData.SchoolName} OSCE exam. Please take a
                  moment to prepare for the exam. During the exam, you will
                  complete a series of simulated case environments where you
                  will be assessed on your ability to diagnose and treat the
                  presenting patient.
                  <br />
                  <br />
                  In between cases, you will have the ability to take a short
                  break. The amount of break time displayed is the{" "}
                  <strong>total break time</strong> you have remaining for your{" "}
                  <strong>entire exam</strong>. So you can choose to take a
                  longer break and use your break time sooner, or take shorter
                  breaks if you wish to save your break time for later in the
                  exam.
                </p>
              ) : (
                <p className="examTransitionContenParagraph">
                  You&apos;ve completed {currentCaseIndex} of your{" "}
                  {userData.CasesArray.length} total cases. Remember that{" "}
                  <strong>break time is shared across all case breaks</strong>,
                  so be sure to save enough for your last few cases. If you run
                  out of break time, your next case will automatically begin
                  after completing the previous case.
                </p>
              )}
              <div className="examTransitionCurrentCaseWrapper">
                <h2 className="examTransitionCurrentCaseTitle">
                  Upcoming Case ({currentCaseIndex + 1}/
                  {userData.CasesArray.length})
                </h2>
                <button
                  className="examTransitionCurrentCaseViewAllButton"
                  onClick={() => setShowExamProgressModal(true)}
                >
                  View All Cases
                </button>
                <div className="examTransitionCurrentCaseContentContainer">
                  <h3>{currentCaseIndex + 1}</h3>
                  <p>Case ID: {userData.CasesArray[currentCaseIndex]}</p>
                </div>
              </div>
              {!isFirstCase ? (
                <ExamTimer
                  initialTimeInSeconds={userData.BreakTime}
                  breakTimePassed={breakTimePassed}
                  setBreakTimePassed={setBreakTimePassed}
                  handleTimerFinish={handleStartExam}
                />
              ) : null}
              {startingExam ? (
                <div className="examTransitionLoaderWrapper">
                  <MDSpinner size={40} singleColor="#0b335d" />
                </div>
              ) : (
                <button
                  className="tertiaryButton examTransitionContentButton"
                  style={{ marginTop: "14px" }}
                  onClick={handleStartExam}
                >
                  Begin Exam
                </button>
              )}
            </div>
          ) : (
            <div className="examTransitionContentWrapper">
              <h1 className="examTransitionContentTitle">
                {userData.SchoolName}
              </h1>
              <p className="examTransitionContenParagraph">
                You have successfully completed the OSCE Exam. Your score has
                been made available to your preceptor as well as your
                school&apos;s administrator. You may now safely close this page.
              </p>
            </div>
          )}
          <div
            className="examTransitionContentLogoWrapper"
            style={{ backgroundColor: userData.SchoolLogoBackgroundColor }}
          >
            <img
              src={`https://osce.ccscases.com/logos/${userData.SchoolLogo}`}
              className="examTransitionContentLogo"
              alt="School Logo"
            />
          </div>
        </div>
      )}
      <div className="examTransitionAdvertisementContainer">
        <p className="examTransitionCCSCasesAdvertText">A</p>
        <img
          src={CCSCasesLogo}
          className="examTransitionCCSCasesAdvertLogo"
          alt="CCS Cases Company Logo"
        />
        <p className="examTransitionCCSCasesAdvertText">Application</p>
      </div>
      {showExamProgressModal ? (
        <ExamProgressModal
          casesArray={userData.CasesArray}
          currentCaseIndex={currentCaseIndex}
          closeModal={() => setShowExamProgressModal(false)}
          breakTimeInSeconds={userData.BreakTime - breakTimePassed}
        />
      ) : null}
    </div>
  );
};

export default ExamTransitionPage;
