import React, { useState } from "react";
import "./ExitWarningModal.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

const ExitWarningModal = ({ closeModal, handleConfirm }) => {
  const [warningAcknowledged, setWarningAcknowledged] = useState(false);

  return (
    <div className="modalPageWrapper">
      <div className="modalBackdrop" />
      <div className="modalContainer">
        <h2 className="modalHeader">Exit Case?</h2>
        <p className="modalParagraph">
          Are you sure you want to exit the case? This will <strong>NOT</strong>{" "}
          submit your exam for grading. Exiting your exam without plans to
          complete it later will result in you receiving a score of 0 for this
          case and all remaining incomplete cases.
          <br />
          <br />
          To correctly end your case, you must advance the clock until the case
          ends.
        </p>
        <div className="modalCheckboxWrapper">
          <button
            className="modalCheckbox"
            onClick={() => setWarningAcknowledged(!warningAcknowledged)}
          >
            {warningAcknowledged && <FontAwesomeIcon icon={faCheck} />}
          </button>
          <p className="modalCheckboxText">
            I have read the above warning and wish to exit my exam early
          </p>
        </div>
        <div className="modalButtonsWrapper">
          <button className="modalCancelButton" onClick={closeModal}>
            Cancel
          </button>
          <button
            className="modalConfirmButton"
            onClick={handleConfirm}
            disabled={!warningAcknowledged}
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

export default ExitWarningModal;
