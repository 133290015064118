import React, { useState, useRef, useEffect } from "react";
import "./ButtonListPopup.css";

const ButtonListPopup = ({
  showButtonListPopup,
  setShowButtonListPopup,
  mousePos,
  children,
}) => {
  const buttonListPopupRef = useRef();
  const [popupYPos, setPopupYPos] = useState(mousePos.y);
  const [popupXPos, setPopupXPos] = useState(0);
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      event.stopPropagation();
      if (
        buttonListPopupRef.current &&
        !buttonListPopupRef.current.contains(event.target)
      ) {
        if (showButtonListPopup) setShowButtonListPopup(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [buttonListPopupRef, showButtonListPopup, setShowButtonListPopup]);

  useEffect(() => {
    const handleResize = () => {
      setShowButtonListPopup(false);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [setShowButtonListPopup]);

  const getPopupYPosition = () => {
    if (!buttonListPopupRef.current) return mousePos.y;
    // Calculate the distance from the top of the page to the bottom of the window
    const pageHeight = document.body.getBoundingClientRect().height;
    const scrollableYSpace = pageHeight - window.innerHeight - window.scrollY;
    const bottomOfWindowFromTopOfDocument = pageHeight - scrollableYSpace;

    // Calculate the bottom of the popup
    const popupRect = buttonListPopupRef.current.getBoundingClientRect();
    const bottomOfPopup = mousePos.y + window.scrollY + popupRect.height;

    // If the popup is going to go off the bottom of the page, move the bottom of the popup to the bottom of the window
    if (bottomOfPopup >= bottomOfWindowFromTopOfDocument) {
      return bottomOfWindowFromTopOfDocument - popupRect.height;
    } else {
      return mousePos.y + window.scrollY;
    }
  };

  const getPopupXPosition = () => {
    if (!buttonListPopupRef.current) return mousePos.x;
    const popupRect = buttonListPopupRef.current.getBoundingClientRect();
    if (popupRect.left < 0 || mousePos.x - popupRect.width < 0) {
      return 0;
    } else {
      return mousePos.x - popupRect.width;
    }
  };

  useEffect(() => {
    setPopupYPos(getPopupYPosition());
    setPopupXPos(getPopupXPosition());
    setIsPopupVisible(true);
  }, [buttonListPopupRef]);

  return (
    <div
      className="adminPortalTableManageStudentsPopupContainer"
      style={{
        top: popupYPos,
        left: popupXPos,
        visibility: isPopupVisible ? "initial" : "none",
        opacity: isPopupVisible ? 1 : 0,
      }}
      ref={buttonListPopupRef}
    >
      {children}
    </div>
  );
};

export default ButtonListPopup;
