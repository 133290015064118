import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useMemo, useState } from "react";
import getCategoryIconFromString from "../../Util/getCategoryIconFromString";
import {
  faArrowDown,
  faArrowUp,
  faEllipsisVertical,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import "./GroupsPage.css";
import { OSCEAdminContext } from "../..";
import ButtonListPopup from "../../Components/ButtonListPopup/ButtonListPopup";
import GroupListModal from "../../Components/Modals/GroupListModal";

const GroupsPage = () => {
  const { userData, mousePosition } = useContext(OSCEAdminContext);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("All Cases");
  const [selectedSort, setSelectedSort] = useState("Case Id");
  const [selectedGroupIndex, setSelectedGroupIndex] = useState(0);
  const groupData = useMemo(() => {
    return userData.GradesData.groupsPageData[selectedGroupIndex];
  }, [userData.GradesData.groupsPageData, selectedGroupIndex]);
  const [filteredCases, setFilteredCases] = useState(groupData?.cases || []);
  const [showMoreHeaderOptions, setShowMoreHeaderOptions] = useState(false);
  const [isCategoriesSectionExpanded, setIsCategoriesSectionExpanded] =
    useState(window.innerWidth <= 600 ? false : true);
  const [selectGroupPopup, setSelectGroupPopup] = useState(false);

  useEffect(() => {
    let filteredCases = groupData?.cases.filter((caseData) => {
      if (
        caseData.caseTitle.toLowerCase().includes(searchTerm.toLowerCase()) ||
        caseData.caseDiagnosis
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        caseData.caseId
          .toString()
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
      ) {
        return true;
      }
      return false;
    });

    if (selectedCategory !== "All Cases") {
      filteredCases = filteredCases.filter(
        (caseData) => caseData.category === selectedCategory
      );
    }

    switch (selectedSort) {
      case "Title":
        filteredCases = filteredCases.sort((a, b) =>
          a.caseTitle.localeCompare(b.caseTitle)
        );
        break;
      case "Diagnosis":
        filteredCases = filteredCases.sort((a, b) =>
          a.caseDiagnosis.localeCompare(b.caseDiagnosis)
        );
        break;
      case "School Score":
        filteredCases = filteredCases.sort(
          (a, b) => b.schoolAverageGrade - a.schoolAverageGrade
        );
        break;
      case "All Schools Score":
        filteredCases = filteredCases.sort(
          (a, b) => b.allSchoolsAverageScore - a.allSchoolsAverageScore
        );
        break;
      case "Performance":
        filteredCases = filteredCases.sort(
          (a, b) => b.performance - a.performance
        );
        break;
      case "Completion Count":
        filteredCases = filteredCases.sort(
          (a, b) => b.completionCount - a.completionCount
        );
        break;
      default:
        break;
    }

    setFilteredCases(filteredCases);
  }, [searchTerm, selectedCategory, selectedSort, selectedGroupIndex]);

  const getNoCasesText = () => {
    let noCasesText = "No cases found";
    if (searchTerm) {
      noCasesText += ` for "${searchTerm}"`;
    }
    if (selectedCategory !== "All Cases") {
      noCasesText += ` in category "${selectedCategory}"`;
    }
    noCasesText += ` for group ${groupData.groupId}${
      groupData.groupName ? ` - ${groupData.groupName}` : ""
    }`;

    noCasesText += ".";

    return noCasesText;
  };

  const handleMobileCategorySelect = (e) => {
    setSelectedCategory(e.target.value);
    setShowMoreHeaderOptions(false);
  };

  const handleMobileSortSelect = (e) => {
    setSelectedSort(e.target.value);
    setShowMoreHeaderOptions(false);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 600) {
        setIsCategoriesSectionExpanded(true);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [setIsCategoriesSectionExpanded]);

  const handleConfirmSelectedGroup = (selectedGroup) => {
    console.log(selectedGroup);
    // Find index of selected group
    const selectedGroupIndex = userData.GradesData.groupsPageData.findIndex(
      (group) => group.groupId === selectedGroup.groupId
    );

    setSelectedGroupIndex(selectedGroupIndex);
    setSelectGroupPopup(false);
  };

  if (!groupData || groupData.cases.length === 0) {
    return (
      <div className="adminGradesPageWrapper">
        <div className="adminGradesTitleContainer">
          <h2>No Group Data Found</h2>
        </div>
      </div>
    );
  }

  return (
    <div className="adminGradesPageWrapper">
      <div className="adminGradesTitleContainer">
        <div className="adminGradesTitleTextWrapper">
          <h2>
            Group {groupData.groupId}
            {groupData.groupName ? ` - ${groupData.groupName}` : ""}
          </h2>
          <button onClick={() => setSelectGroupPopup(true)}>
            Change Selected Group
          </button>
        </div>
        <div className="adminGradesTitleValuesListWrapper">
          <div className="adminGradesTitleValueWrapper">
            <h3>Students In Group</h3>
            <p>{groupData.studentsInGroup}</p>
          </div>
          <div className="adminGradesTitleValueWrapper">
            <h3>Group Average Grade</h3>
            <p>{groupData.groupAverageGrade}%</p>
          </div>
          <div className="adminGradesTitleValueWrapper">
            <h3>School Average Grade</h3>
            <p>{groupData.schoolAverageGrade}%</p>
          </div>
        </div>
      </div>
      <div className="adminGradesCategoriesContainer">
        <h2>Category Performance</h2>
        <div className="adminGradesCategoryListWrapper">
          {groupData.categories
            .slice(0, isCategoriesSectionExpanded ? 6 : 2)
            .map((category) => (
              <div
                className="adminGradesCategoryListItemContainer"
                key={`groups-page-category-${category.categoryName}`}
              >
                <FontAwesomeIcon
                  icon={getCategoryIconFromString(category.categoryName)}
                />
                <div className="adminGradesCategoryListItemTextWrapper">
                  <h3>{category.categoryName}</h3>
                  <p>Group: {category.groupAverageGrade}%</p>
                  <p>School: {category.schoolAverageGrade}%</p>
                </div>
                <div
                  className="adminGradesCategoryListItemPerformanceWrapper"
                  style={{
                    color: category.performance >= 0 ? "#13923e" : "#a90101",
                  }}
                >
                  <FontAwesomeIcon
                    icon={category.performance >= 0 ? faArrowUp : faArrowDown}
                  />
                  <p>
                    {category.performance >= 0
                      ? "Outperforming"
                      : "Underperforming"}{" "}
                    By {Math.abs(category.performance)}%
                  </p>
                </div>
              </div>
            ))}
          {!isCategoriesSectionExpanded && groupData.categories.length > 0 && (
            <button
              className="adminGradesCategoriesExpandButton"
              onClick={() =>
                setIsCategoriesSectionExpanded(!isCategoriesSectionExpanded)
              }
            >
              Show More
            </button>
          )}
          {groupData.categories.length === 0 && (
            <div className="adminCasesPageNoCasesFoundContainer adminGroupsPageCategoriesNoDataMessage">
              <p>
                No category data for group {groupData.groupId}
                {groupData.groupName ? ` - ${groupData.groupName}` : ""}
              </p>
            </div>
          )}
        </div>
      </div>
      <div className="adminCasesPageCasesContainer">
        <h2 className="adminCasesHeader">
          Case Performance ({groupData.cases.length} Completed)
        </h2>
        <div className="adminCasesPageHeaderOptionsWrapper">
          <div className="examRulesStudentHeaderSearchWrapper">
            <FontAwesomeIcon icon={faSearch} />
            <input
              type="text"
              placeholder="Search cases..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <select
            className="examRulesStudentHeaderSelect"
            value={selectedCategory}
            onChange={(e) => setSelectedCategory(e.target.value)}
          >
            <option value="All Cases">View All Categories</option>
            <option value="Internal Medicine">Internal Medicine</option>
            <option value="Neurology">Neurology</option>
            <option value="OB/GYN">OB/GYN</option>
            <option value="Pediatrics">Pediatrics</option>
            <option value="Emergency Medicine">Emergency Medicine</option>
            <option value="Psychiatry">Psychiatry</option>
          </select>
          <div className="adminCasesPageHeaderOptionsDivider" />
          <select
            className="examRulesStudentHeaderSelect"
            value={selectedSort}
            onChange={(e) => setSelectedSort(e.target.value)}
          >
            <option value="Case Id">Sort By CaseId</option>
            <option value="Title">Sort By Title A-Z</option>
            <option value="Diagnosis">Sort By Diagnosis A-Z</option>
            <option value="School Score">Sort By Highest School Score</option>
            <option value="All Schools Score">
              Sort By Highest All Schools Score
            </option>
            <option value="Performance">Sort By Performance</option>
            <option value="Completion Count">Sort By Completion Count</option>
          </select>
          <button
            className="adminCasesPageHeaderMobileMoreOptionsButton"
            onClick={() => setShowMoreHeaderOptions(true)}
          >
            <FontAwesomeIcon icon={faEllipsisVertical} />
          </button>
        </div>
        <div className="adminCaseListContainer">
          {filteredCases.map((caseData, index) => (
            <div
              className="adminCaseListElementContainer"
              key={`groups-page-case-list-${caseData.caseId}`}
            >
              <h3>{caseData.caseId}</h3>
              <div className="adminCaseListElementTitleWrapper">
                <h4>
                  {caseData.caseTitle} - {caseData.caseDiagnosis}
                </h4>
                <FontAwesomeIcon
                  icon={getCategoryIconFromString(caseData.category)}
                />
                <p>
                  {caseData.category} | {caseData.completionCount} Completions
                </p>
              </div>
              <div className="adminCaseListValueListWrapper adminGroupsCaseListValueListWrapper">
                <div className="adminCaseListValueWrapper">
                  <h5>Group Average Score</h5>
                  <p>{caseData.groupAverageGrade}%</p>
                </div>
                <div className="adminCaseListValueWrapper">
                  <h5>School Average Score</h5>
                  <p>{caseData.schoolAverageGrade}%</p>
                </div>
                <div
                  className="adminCaseListPerformanceWrapper"
                  style={{
                    color: caseData.performance >= 0 ? "#13923e" : "#a90101",
                  }}
                >
                  <FontAwesomeIcon
                    icon={caseData.performance >= 0 ? faArrowUp : faArrowDown}
                  />
                  <p>
                    {caseData.performance >= 0
                      ? "Outperforming"
                      : "Underperforming"}{" "}
                    By {Math.abs(caseData.performance)}%
                  </p>
                </div>
              </div>
            </div>
          ))}
          {filteredCases.length === 0 && (
            <div className="adminCasesPageNoCasesFoundContainer">
              <p>{getNoCasesText()}</p>
            </div>
          )}
        </div>
      </div>
      {showMoreHeaderOptions && (
        <ButtonListPopup
          mousePos={{
            x: mousePosition.x,
            y: mousePosition.y,
          }}
          setShowButtonListPopup={setShowMoreHeaderOptions}
          showButtonListPopup={showMoreHeaderOptions}
        >
          <select
            className="examRulesStudentHeaderSelect examRulesButtonListHeaderSelect"
            value={selectedCategory}
            onChange={handleMobileCategorySelect}
          >
            <option value="All Cases">View All Categories</option>
            <option value="Internal Medicine">Internal Medicine</option>
            <option value="Neurology">Neurology</option>
            <option value="OB/GYN">OB/GYN</option>
            <option value="Pediatrics">Pediatrics</option>
            <option value="Emergency Medicine">Emergency Medicine</option>
            <option value="Psychiatry">Psychiatry</option>
          </select>
          <select
            className="examRulesStudentHeaderSelect examRulesButtonListHeaderSelect"
            value={selectedSort}
            onChange={handleMobileSortSelect}
          >
            <option value="Case Id">Sort By CaseId</option>
            <option value="Title">Sort By Title A-Z</option>
            <option value="Diagnosis">Sort By Diagnosis A-Z</option>
            <option value="School Score">Sort By Highest School Score</option>
            <option value="All Schools Score">
              Sort By Highest All Schools Score
            </option>
            <option value="Performance">Sort By Performance</option>
            <option value="Completion Count">Sort By Completion Count</option>
          </select>
        </ButtonListPopup>
      )}
      {selectGroupPopup && (
        <GroupListModal
          setShowModal={setSelectGroupPopup}
          confirmSelection={handleConfirmSelectedGroup}
          showAddGroupButton={false}
          subHeader={`Select a group to view its grades`}
        />
      )}
    </div>
  );
};

export default GroupsPage;
