import React, { useEffect, useState } from "react";
import LoginPage from "../../NewLogin/LoginPage";
import ExamTransitionPage from "./ExamTransitionPage";
import { fetchStartCase } from "../../Functions/FetchStartCase";
import Simulation from "../../CCSCasesSimulation/Simulation";
import { updateLocation } from "../../Functions/Simulation/UpdateLocation";
import { toast, ToastContainer } from "react-toastify";
import ExitWarningModal from "./ExitWarningModal/ExitWarningModal";

const WEB_SERVER_ROUTE = "https://osce.ccscases.com";
const OSCEStudent = ({ userData, updateUserData }) => {
  const [pageDisplayed, setPageDisplayed] = useState("Simulation Transition");
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [breakTimePassed, setBreakTimePassed] = useState(0);
  const [startCaseData, setStartCaseData] = useState(null);
  const [simulationData, setSimulationData] = useState(null);
  const [caseData, setCaseData] = useState(null);
  const [startingExam, setStartingExam] = useState(false);
  const [loadingCaseData, setLoadingCaseData] = useState(false);
  const [currentCaseIndex, setCurrentCaseIndex] = useState(
    userData.initialCaseIndex === -1
      ? userData.CasesArray.length
      : userData.initialCaseIndex
  );
  const [displayExitWarning, setDisplayExitWarning] = useState(false);

  useEffect(() => {
    window.addEventListener("resize", updatePageHeight);
    return () => {
      window.removeEventListener("resize", updatePageHeight);
    };
  });

  const updatePageHeight = () => {
    setWindowHeight(window.innerHeight);
  };

  const updateBreakTime = () => {
    fetch(`${WEB_SERVER_ROUTE}/changebreaktime.webapi`, {
      method: "POST",
      headers: {
        Token: userData.Token,
      },
      body: JSON.stringify({
        BreakTimeInSeconds: userData.BreakTime - breakTimePassed,
      }),
    })
      .then((response) => {
        if (response.ok) {
          return response.text();
        } else {
          throw new Error("Server Error");
        }
      })
      .then(() => {
        updateUserData((currentUserData) => {
          return {
            ...currentUserData,
            BreakTime: currentUserData.BreakTime - breakTimePassed,
          };
        });
        setBreakTimePassed(0);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleStartExam = async () => {
    setStartingExam(true);
    try {
      const startCaseResult = await fetchStartCase(
        userData.CasesArray[currentCaseIndex],
        userData.CustomerId,
        userData.Token,
        userData.OsceGuid
      );

      if (!userData.OsceGuid) {
        updateUserData((currentUserData) => {
          return {
            ...currentUserData,
            OsceGuid: startCaseResult.simulationData.OsceGuid,
          };
        });
      }
      updateBreakTime();
      setStartingExam(false);
      setStartCaseData(startCaseResult.startCaseData);
      setSimulationData(startCaseResult.simulationData);
      setCaseData(startCaseResult.caseData);
      setPageDisplayed("Simulation");
    } catch (e) {
      toast(
        "We ran into an error starting your exam. Please have your administrator try reassigning your cases."
      );
      setStartingExam(false);
    }
  };

  const handleUpdateLocation = (locationId) => {
    const simulationDataWithUpdatedLocation = updateLocation(
      simulationData,
      locationId
    );
    setSimulationData(simulationDataWithUpdatedLocation);
  };

  const handleFinishExam = async () => {
    setPageDisplayed("Simulation Transition");
    setLoadingCaseData(true);
    await fetchGrading();
    setLoadingCaseData(false);
  };

  const handlePrematureExamExit = () => {
    setDisplayExitWarning(true);
  };

  const confirmPrematureExit = () => {
    setDisplayExitWarning(false);
    window.onbeforeunload = null;
    window.location.reload();
  };

  const fetchGrading = async () => {
    await fetch(`${WEB_SERVER_ROUTE}/grading.webapi`, {
      method: "POST",
      headers: {
        Token: userData.Token,
        "Content-Type": "text/plain",
      },
      body: JSON.stringify({
        customerId: userData.CustomerId,
        caseguid: simulationData.caseguid,
        caseId: userData.CasesArray[currentCaseIndex],
      }),
    })
      .then((response) => {
        if (response.ok) {
          return response.text();
        } else if (response.status === 401) {
          throw new Error("Authentication Error");
        } else {
          throw new Error("Server Error");
        }
      })
      .then(() => {
        setCurrentCaseIndex(currentCaseIndex + 1);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  let applicationView = null;

  switch (pageDisplayed) {
    case "Simulation":
      applicationView = (
        <Simulation
          caseData={caseData}
          setAuthenticationError={() => null}
          simulationData={simulationData}
          userData={userData}
          userProfile={null} //userProfile is only used for calls to logFetchData and submitLogs. Most of this data is contained in the userData object
          updateLocation={handleUpdateLocation}
          fetchGrading={handleFinishExam}
          timedExam={true} // Likely won't support the untimed exam feature
          customTimeLimit={"none"} // Likely won't support the untimed exam feature, so this will remain set to none
          closeGrading={handlePrematureExamExit}
          networkLag={"none"} // Same as untimed exams, network lag is unliked to be supported
          startCaseData={startCaseData}
          route={WEB_SERVER_ROUTE}
          fetchOutstanding={false}
          primaryColor={"#0B335D"}
        />
      );
      break;
    default:
      applicationView = (
        <ExamTransitionPage
          breakTimePassed={breakTimePassed}
          setBreakTimePassed={setBreakTimePassed}
          handleStartExam={handleStartExam}
          userData={userData}
          startingExam={startingExam}
          currentCaseIndex={currentCaseIndex}
          loadingCaseData={loadingCaseData}
        />
      );
      break;
  }

  return (
    <div style={{ height: windowHeight }}>
      {applicationView}
      <ToastContainer theme="light" />
      {displayExitWarning && (
        <ExitWarningModal
          closeModal={() => setDisplayExitWarning(false)}
          handleConfirm={confirmPrematureExit}
        />
      )}
    </div>
  );
};

export default OSCEStudent;
