import React from "react";
import "./ExamProgressModal.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { faTimer } from "@fortawesome/pro-solid-svg-icons";

const ExamProgressModal = ({
  casesArray,
  currentCaseIndex,
  breakTimeInSeconds,
  closeModal,
}) => {
  return (
    <div className="modalPageWrapper">
      <div className="modalBackdrop" />
      <div className="modalContainer">
        <h2 className="modalHeader">Cases In Exam</h2>
        {currentCaseIndex !== 0 ? (
          <p className="modalSubHeader">
            Break Time Remaining:{" "}
            {breakTimeInSeconds > 60
              ? `${Math.floor(breakTimeInSeconds / 60)} Minutes `
              : ""}
            {breakTimeInSeconds % 60} Seconds
          </p>
        ) : null}
        <div className="examProgressModalContentContainer">
          {casesArray.map((caseId, index) => {
            return (
              <div
                className="examProgressModalContentElementContainer"
                key={index}
                style={{
                  opacity: index !== currentCaseIndex ? 0.4 : 1,
                }}
              >
                <h3>{index + 1}</h3>
                <p>Case ID: {caseId}</p>
                {index < currentCaseIndex ? (
                  <FontAwesomeIcon icon={faCircleCheck} />
                ) : index === currentCaseIndex ? (
                  <FontAwesomeIcon icon={faTimer} />
                ) : null}
              </div>
            );
          })}
        </div>
        <button className="modalCloseButton" onClick={closeModal}>
          Close
        </button>
      </div>
    </div>
  );
};

export default ExamProgressModal;
