import React, { useContext, useEffect, useState } from "react";
import "./AccountSettings.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog } from "@fortawesome/free-solid-svg-icons";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import ReportBugModal from "../Components/Modals/ReportBugModal/ReportBugModal";
import ChangePasswordModal from "../Components/Modals/ChangePasswordModal/ChangePasswordModal";
import { OSCEAdminContext } from "..";
import MDSpinner from "react-md-spinner";

const AccountSettings = () => {
  const [showReportBugModal, setShowReportBugModal] = useState(false);
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
  const [timezoneChangeOutstanding, setTimezoneChangeOutstanding] =
    useState(false);
  const {
    displayNotification,
    userData,
    route,
    handleExpiredUserSession,
    updateUserData,
  } = useContext(OSCEAdminContext);

  // Using a dummy rerender here to make up for a bug in the masonry library
  const [reRender, setReRender] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setReRender(!reRender);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [setReRender, reRender]);

  const handleChangeTimezone = async (newTimeZone) => {
    setTimezoneChangeOutstanding(true);
    await fetch(`${route}/updatetimezone.webapi`, {
      method: "POST",
      headers: {
        Token: userData.Token,
      },
      body: JSON.stringify({
        CustomerId: userData.CustomerId,
        TimeZone: newTimeZone,
      }),
    })
      .then((response) => {
        if (response.ok) {
          return response.text();
        } else if (response.status === 401) {
          handleExpiredUserSession();
        } else {
          throw new Error("Failed to update timezone");
        }
      })
      .then((data) => {
        if (data === "Time Zone Updated") {
          displayNotification("Timezone successfully updated");
          updateUserData({ ...userData, TimeZone: newTimeZone });
        } else displayNotification("Failed to update timezone");
      })
      .catch((error) => {
        displayNotification(error.message);
      });
    setTimezoneChangeOutstanding(false);
  };

  return (
    <div className="adminAccountSettingsPageWrapper">
      <div className="adminAccountSettingsHeaderContainer">
        <div>
          <FontAwesomeIcon icon={faCog} />
        </div>
        <h1>Account Settings</h1>
        <p>
          {userData.CustomerFirstName} {userData.CustomerMiddleName}{" "}
          {userData.CustomerLastName} | {userData.CustomerUserName}
        </p>
      </div>
      <ResponsiveMasonry
        columnsCountBreakPoints={{ 0: 1, 600: 2, 900: 3, 1240: 4 }}
      >
        <Masonry sequential gutter="15px">
          <div className="adminAccountSettingsCardContainer">
            <h2>Change Password</h2>
            <p>Update your account password.</p>
            <button onClick={() => setShowChangePasswordModal(true)}>
              Change Password
            </button>
          </div>
          <div className="adminAccountSettingsCardContainer">
            <h2>Add Credits</h2>
            <p>
              You currently have <strong>{userData.OsceCredits} credits</strong>{" "}
              remaining. Add more credits to your account to support more
              student exams.
            </p>
            <button>Add Credits</button>
          </div>
          <div className="adminAccountSettingsCardContainer">
            <h2>Report Bug/Feedback</h2>
            <p>
              Noticed an issue with our application? You can inform us here.
            </p>
            <button onClick={() => setShowReportBugModal(true)}>
              Report Bug/Feedback
            </button>
          </div>
          <div className="adminAccountSettingsCardContainer">
            <h2>Logout</h2>
            <p>Return to the login screen.</p>
            <button onClick={() => window.location.reload()}>Logout</button>
          </div>
          <div className="adminAccountSettingsCardContainer">
            <h2>Time Zone</h2>
            <p>
              Setting your time zone will make device times, like case
              completion dates, display in your local time instead of UTC.
            </p>
            {timezoneChangeOutstanding ? (
              <div
                style={{
                  padding: "5px 0px",
                  display: "grid",
                  alignItems: "center",
                  justifyItems: "center",
                }}
              >
                <MDSpinner size={35} singleColor="#0B335D" />
              </div>
            ) : (
              <select
                value={userData.TimeZone}
                onChange={(e) => handleChangeTimezone(e.target.value)}
              >
                <option value="-12">GMT -12 US Minor Outlying Islands</option>
                <option value="-11">GMT -11 US Minor Outlying Islands</option>
                <option value="-10">GMT -10 Honolulu</option>
                <option value="-9">GMT -9 Alaska Time</option>
                <option value="-8">GMT -8 Pacific Time</option>
                <option value="-7">GMT -7 Mountain Time</option>
                <option value="-6">GMT -6 Central Time</option>
                <option value="-5">GMT -5 Eastern Time</option>
                <option value="-4">GMT -4 Atlantic Time</option>
                <option value="-3">GMT -3 Greenland</option>
                <option value="-2">GMT -2 Brazil</option>
                <option value="-1">GMT -1 Portugal</option>
                <option value="+0">GMT +0 United Kingdom</option>
                <option value="+1">GMT +1 Paris</option>
                <option value="+2">GMT +2 Johannesburg</option>
                <option value="+3">GMT +3 Moscow</option>
                <option value="+4">GMT +4 Dubai</option>
                <option value="+5">GMT +5 Karachi</option>
                <option value="+6">GMT +6 Dhaka</option>
                <option value="+7">GMT +7 Jakarta</option>
                <option value="+8">GMT +8 Shanghai</option>
                <option value="+9">GMT +9 Tokyo</option>
                <option value="+10">GMT +10 Sydney</option>
                <option value="+11">GMT +11 Nouméa</option>
                <option value="+12">GMT +12 Auckland</option>
                <option value="+13">GMT +13 Tonga</option>
                <option value="+14">GMT +14 Kiribati</option>
              </select>
            )}
          </div>
        </Masonry>
      </ResponsiveMasonry>
      {showReportBugModal && (
        <ReportBugModal closeModal={() => setShowReportBugModal(false)} />
      )}
      {showChangePasswordModal && (
        <ChangePasswordModal
          closeModal={() => setShowChangePasswordModal(false)}
        />
      )}
    </div>
  );
};

export default AccountSettings;
