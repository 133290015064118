import getDaysFromDate from "../Functions/GetDaysFromDate";
import StaticCasesInfo from "../static_cases_info.json";
import getActionLogData from "../Functions/IncompleteStart/getActionLogData";
import formatCurrentOrders from "../Functions/IncompleteStart/formatCurrentOrders";
import formatCompletedOrders from "../Functions/IncompleteStart/formatCompletedOrders";

const formatStartingOrders = (currentOrders) => {
  let startingOrders = JSON.parse(currentOrders);
  for (let i = 0; i < startingOrders.length; ++i) {
    let reportTime = new Date(`${startingOrders[i].ReportTime}Z`);
    let timeOrdered = new Date(`${startingOrders[i].TimeOrdered}`);
    let currentVirtualTime = new Date(
      `${startingOrders[i].CurrentVirtualTime}Z`
    );

    if (startingOrders[i].ReportTime === "0001-01-01T00:00:00") {
      startingOrders[i].ReportTime = null;
    } else {
      startingOrders[i].ReportTime = {
        days: getDaysFromDate(reportTime),
        hours: reportTime.getUTCHours(),
        minutes: reportTime.getUTCMinutes(),
      };
    }

    startingOrders[i].TimeOrdered = {
      days: getDaysFromDate(timeOrdered),
      hours: timeOrdered.getUTCHours(),
      minutes: timeOrdered.getUTCMinutes(),
    };

    startingOrders[i].CurrentVirtualTime = {
      days: getDaysFromDate(currentVirtualTime),
      hours: currentVirtualTime.getUTCHours(),
      minutes: currentVirtualTime.getUTCMinutes(),
    };
  }
  return startingOrders;
};

const sortSpecialOrderPhrases = (SpecialOrderPhrases) => {
  SpecialOrderPhrases.sort((a, b) => (a === b ? 0 : a < b ? -1 : 1));
  let arrayWithoutCommas = SpecialOrderPhrases.filter(
    (val) => !val.includes(",")
  );
  let arrayWithCommas = SpecialOrderPhrases.filter((val) => val.includes(","));
  return [...arrayWithoutCommas, ...arrayWithCommas];
};

const WEB_SERVER_ROUTE = "https://osce.ccscases.com";
export const fetchStartCase = async (
  caseID,
  customerId,
  userToken,
  osceGuid
) => {
  let startCaseData = {
    completedOrders: [],
    currentLocation: 2,
    currentOrders: [],
    currentPatientUpdate: "",
    currentPhysicalExamId: 10,
    currentState: "B0",
    currentVirtualTime: "",
    currentVitalsId: 9,
    timeToPatientUpdate: 0,
    popupQueue: ["introduction", "vitals", "history"],
    simulationOptionSelected: -1,
    centerContent: false,
    elapsedSimulatedCaseTime: {
      days: 0,
      hours: 0,
      minutes: 0,
    },
    physicalResults: [],
    currentActualTime: 0,
    caseEnds: false,
    patientUpdates: [],
    vitalsResults: [],
  };
  return await fetch(`${WEB_SERVER_ROUTE}/startcase.webapi`, {
    method: "POST",
    headers: {
      Token: userToken,
      "Content-Type": "text/plain",
    },
    body: JSON.stringify({
      customerId: customerId,
      caseId: caseID,
      OsceGuid: osceGuid,
    }),
  })
    .then((response) => {
      if (response.status === 401) {
        this.setState({ authenticationError: true });
        throw new Error("Authentication Error");
      } else {
        return response.text();
      }
    })
    .then((response) => {
      let result = response;
      if (result.includes("Error: ")) {
        window.alert("An unexpected error has occured: " + result);
        throw new Error(result);
      }

      let simulationData = JSON.parse(result);

      console.log(simulationData);

      let date = new Date(`${simulationData.InitialStartTime}Z`);
      simulationData.InitialStartTime = {
        days: getDaysFromDate(date),
        hours: date.getUTCHours(),
        minutes: date.getUTCMinutes(),
      };

      startCaseData.elapsedSimulatedCaseTime = {
        days: simulationData.InitialStartTime.days,
        hours: simulationData.InitialStartTime.hours,
        minutes: simulationData.InitialStartTime.minutes,
      };

      if (simulationData.StartMed) {
        startCaseData.currentOrders = formatStartingOrders(
          simulationData.CurrentOrders
        );
      }

      // Sort SpecialOrderPhrases, first alphabetically, then put comma seperated terms last
      if (simulationData.SpecialOrderPhrases) {
        simulationData.SpecialOrderPhrases = sortSpecialOrderPhrases(
          simulationData.SpecialOrderPhrases
        );
      }

      let CaseData = StaticCasesInfo.lc.find(
        (staticCaseData) => staticCaseData.ID === Number(caseID)
      );

      const caseData = {
        ID: caseID,
        TIMEMODEDESC: CaseData.TIMEMODEDESC,
        ReviewLaterFlag: false,
      };

      console.log({ startCaseData, simulationData, caseData });
      return { startCaseData, simulationData, caseData };
      // if (simulationData.ialist.length === 0) {
      //   console.log({ startCaseData, simulationData, caseData });
      //   return { startCaseData, simulationData, caseData };
      // } else {
      //   // User has incomplete case attempts, they will need to immediately start at the most recent attempt
      //   let incompleteCaseList = [...simulationData.ialist];
      //   // Sort incompleteCaseList by date
      //   incompleteCaseList.sort((a, b) => {
      //     let aDate = new Date(`${a.TimeAttempted}Z`);
      //     let bDate = new Date(`${b.TimeAttempted}Z`);
      //     return bDate - aDate;
      //   });

      //   console.log({
      //     startCaseData,
      //     simulationData,
      //     caseData,
      //     selectedIncompleteCase: incompleteCaseList[0],
      //     customerId,
      //     userToken,
      //   });

      //   // return handleIncompleteCaseStart(
      //   //   startCaseData,
      //   //   simulationData,
      //   //   caseData,
      //   //   incompleteCaseList[0],
      //   //   customerId,
      //   //   userToken
      //   // );
      // }
    })
    .catch((error) => {
      console.log("error", error);
    });
};

const handleIncompleteCaseStart = (
  startCaseData,
  simulationData,
  caseData,
  selectedIncompleteCase,
  customerId,
  userToken
) => {
  let incompleteStartData = {};
  this.setState({ continueCaseLoading: true });
  fetch(`${WEB_SERVER_ROUTE}/incompletestart.webapi`, {
    method: "POST",
    headers: {
      Token: userToken,
      "Content-Type": "text/plain",
    },
    body: JSON.stringify({
      CustomerId: customerId,
      caseguid: simulationData.caseguid,
      CurrentStateId: selectedIncompleteCase.CurrentStateId,
    }),
  })
    .then((response) => {
      this.setState({ continueCaseLoading: false });
      if (response.status === 401) {
        this.setState({ authenticationError: true });
        throw new Error("Authentication Error");
      } else {
        return response.json;
      }
    })
    .then(() => {
      let temp = { ...this.state.simulationData };
      //Determine User's current simulated location
      temp.CaseLocationId = incompleteStartData.CurrentLocation;
      let locations = [
        "Emergency Department",
        "Office",
        "Intensive Care Unit",
        "Home",
        "Inpatient Unit",
      ];
      temp.CaseLocationDesc = locations[temp.CaseLocationId - 1];

      //Format the current simulated time for the case
      let date = new Date(`${incompleteStartData.CurrentVirtualTime}Z`);

      let elapsedSimulatedCaseTime = {
        days: getDaysFromDate(date),
        hours: date.getUTCHours(),
        minutes: date.getUTCMinutes(),
      };

      //Retrieve relevant action log data including patientUpdates, currentActualTime and physical results by calling getActionLogData (imported)
      let {
        patientUpdates,
        currentActualTime,
        physicalResults,
        vitalsResults,
      } = getActionLogData(JSON.parse(incompleteStartData.ActionLog));

      //Format the current orders by calling formatCurrentOrders (imported)
      let currentOrders = formatCurrentOrders(
        JSON.parse(incompleteStartData.CurrentOrders)
      );

      //Format completed orders by calling formatCompletedOrders (imported)
      let completedOrders = formatCompletedOrders(
        JSON.parse(incompleteStartData.CompletedOrders)
      );

      //Construct startCaseData. This is used primarily to instantiate the simulation state with the user's case progress
      let startCaseData = {
        completedOrders,
        currentLocation: incompleteStartData.CurrentLocation,
        currentOrders,
        currentPatientUpdate: incompleteStartData.currentPatientUpdate,
        currentPhysicalExamId: incompleteStartData.currentPhysicalExamId,
        currentState: incompleteStartData.CurrentState,
        currentVirtualTime: temp.InitialStartTime,
        currentVitalsId: incompleteStartData.CurrentVitalsId,
        timeToPatientUpdate: incompleteStartData.timeToPatientUpdate,
        popupQueue: [],
        simulationOptionSelected: 1,
        centerContent: "reviewOrders",
        elapsedSimulatedCaseTime,
        physicalResults,
        currentActualTime:
          currentActualTime === null ? 0 : currentActualTime.getMinutes(),
        caseEnds: incompleteStartData.CaseEnded,
        patientUpdates,
        vitalsResults,
      };
      this.setState({
        simulationData: temp,
        startCaseData,
        applicationView: "simulation",
      });
    })
    .catch((error) => {
      window.alert("An unexpected error has occurred: " + error);
      console.log(error);
    });
};
// .catch((error) => {
//   this.setState({ fetchOutstanding: false });
//   console.log(error);
//   logFetchError(
//     error,
//     this.state.userProfile,
//     this.state.userData,
//     "confirmIncompleteCaseStart FetchError"
//   );
// });
