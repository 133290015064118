import React, { useContext, useState } from "react";
import "./ExamRulesOverview.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import { ExamRulesContext } from "../../ExamRulesPage";
import { OSCEAdminContext } from "../../..";
import ConfirmationModal from "../../../Components/Modals/ConfirmationModal/ConfirmationModal";
import SelectedCasesSubContainer from "./SelectedCasesSubContainer";
import MDSpinner from "react-md-spinner";
import mapCategoryIdToCategory from "../../../Util/MapCategoryIdToCategory";
import { v4 as uuidv4 } from "uuid";

const mapCaseCategoryToAbbreviation = {
  Random: "random",
  "Internal Medicine": "im",
  Neurology: "neuro",
  Pediatrics: "peds",
  Psychiatry: "psych",
  "Emergency Medicine": "em",
  "OB/GYN": "ob",
};

const formatSelectedCasesList = (selectedCaseList) => {
  return selectedCaseList.map((selectedCase, index) => {
    if (selectedCase.type === "case") {
      return {
        CaseSequenceNumber: index + 1,
        CaseId: selectedCase.CaseId,
        Category: null,
      };
    } else {
      return {
        CaseSequenceNumber: index + 1,
        CaseId: -1,
        Category: mapCaseCategoryToAbbreviation[selectedCase.category],
      };
    }
  });
};

const ExamRulesOverview = ({ breakTimeInSeconds, setBreakTimeInSeconds }) => {
  const {
    selectedStudentIds,
    setSelectedStudentIds,
    selectedCaseList,
    setSelectedCaseMap,
    setSelectedCategoryMap,
    setSelectedCaseList,
    setPageDisplayed,
  } = useContext(ExamRulesContext);
  const {
    userData,
    route,
    displayNotification,
    updateUserData,
    handleExpiredUserSession,
  } = useContext(OSCEAdminContext);
  const [
    isClearSelectionConfirmationDisplayed,
    setIsClearSelectionConfirmationDisplayed,
  ] = useState(false);
  const [fetchOutstanding, setFetchOutstanding] = useState(false);

  const selectedStudents = userData.Students.filter((student) => {
    return selectedStudentIds.includes(student.CustomerId);
  });

  const handleRemoveStudent = (studentId) => {
    setSelectedStudentIds((prev) => prev.filter((id) => id !== studentId));
  };

  const handleClearSelection = () => {
    setSelectedStudentIds([]);
    setSelectedCaseList([]);
    setSelectedCaseMap({});
    setSelectedCategoryMap({});
    setIsClearSelectionConfirmationDisplayed(false);
  };

  const fetchApplyRules = async () => {
    setFetchOutstanding(true);
    const notification =
      selectedStudents.length === 1
        ? `${selectedStudents[0].CustomerFirstName} ${selectedStudents[0].CustomerLastName}`
        : `${selectedStudents.length} students`;
    await fetch(`${route}/examRules.webapi`, {
      method: "POST",
      headers: {
        Token: userData.Token,
      },
      body: JSON.stringify({
        StudentIds: selectedStudentIds,
        cases: formatSelectedCasesList(selectedCaseList),
        breaktime: breakTimeInSeconds,
      }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else if (response.status === 401) {
          handleExpiredUserSession();
          return false;
        } else {
          throw new Error("Failed to apply rules");
        }
      })
      .then((data) => {
        if (!data) return;
        displayNotification(`Successfully applied rules to ${notification}`);
        const updatedStudents = userData.Students.map((student) => {
          if (selectedStudentIds.includes(student.CustomerId)) {
            return {
              ...student,
              SpecficCasesOut: data,
              TotalBreakTime: breakTimeInSeconds,
            };
          } else {
            return student;
          }
        });
        updateUserData({ ...userData, Students: updatedStudents });
        setSelectedCategoryMap({});
        let newSelectedCaseList = [];
        let newSelectedCaseMap = {};
        data.forEach((value) => {
          newSelectedCaseList.push({
            CaseCategory: mapCategoryIdToCategory[value.CaseCategoryId],
            CaseId: value.CaseId,
            CaseTitle: null,
            CategoryId: 5,
            Diagnosis: value.Diagnosis,
            type: "case",
            uniqueId: uuidv4(),
          });
          if (newSelectedCaseMap[value.Diagnosis]) {
            newSelectedCaseMap[value.Diagnosis] += 1;
          } else {
            newSelectedCaseMap[value.Diagnosis] = 1;
          }
        });
        setSelectedCaseList(newSelectedCaseList);
        setSelectedCaseMap(newSelectedCaseMap);
      })
      .catch((error) => {
        displayNotification(`Failed to apply rules to ${notification}`);
      })
      .finally(() => {
        setFetchOutstanding(false);
      });
  };

  return (
    <>
      <div className="examRulesOverviewContainer">
        <h1 className="examRulesHeaderText">Exam Rules</h1>
        <p className="examRulesOverviewSubContainerTitle">
          Students Selected ({selectedStudents.length})
        </p>
        <div className="examRulesSubContainer examRulesOverviewSubContainer">
          {selectedStudents.map((student) => (
            <div
              className="examRulesOverviewStudentContainer"
              key={`Overview Selected Student Id - ${student.CustomerId}`}
            >
              <p className="examRulesOverviewStudentName">
                {student.CustomerFirstName} {student.CustomerMiddleName}{" "}
                {student.CustomerLastName}
              </p>
              <button
                className="examRulesOverviewStudentRemoveButton"
                onClick={() => handleRemoveStudent(student.CustomerId)}
              >
                <FontAwesomeIcon icon={faTimes} />
              </button>
            </div>
          ))}
          {selectedStudents.length === 0 && (
            <p className="examRulesOverviewNoElementsSelected">
              No Students Selected
            </p>
          )}
          <button
            className="examRulesOverviewAddElementButton"
            onClick={() => setPageDisplayed("student")}
          >
            <FontAwesomeIcon icon={faPlus} />
            Add Students
          </button>
        </div>
        <SelectedCasesSubContainer />
        <div className="examRulesOverviewInputWrapper">
          <label
            className="examRulesOverviewInputLabel"
            htmlFor="breakTimeInput"
          >
            Break Time In Seconds
          </label>
          <input
            className="examRulesOverviewInput"
            type="number"
            id="breakTimeInput"
            value={breakTimeInSeconds}
            onChange={(e) => setBreakTimeInSeconds(e.target.value)}
          />
        </div>
        {fetchOutstanding ? (
          <div className="modalSpinnerWrapper" style={{ marginBottom: 0 }}>
            <MDSpinner size={35} singleColor="#0B335D" />
          </div>
        ) : (
          <div className="examRulesOverviewButtonContainer">
            <button
              className="examRulesOverviewSecondaryButton"
              onClick={() => setIsClearSelectionConfirmationDisplayed(true)}
              disabled={
                selectedStudents.length === 0 && selectedCaseList.length === 0
              }
            >
              Clear Selection
            </button>

            <button
              className="examRulesOverviewPrimaryButton"
              disabled={
                selectedStudents.length === 0 || selectedCaseList.length === 0
              }
              onClick={fetchApplyRules}
            >
              Apply Rules
            </button>
          </div>
        )}
      </div>
      {isClearSelectionConfirmationDisplayed && (
        <ConfirmationModal
          header="Clear Selections?"
          text="This will clear all selected students and cases"
          closeModal={() => setIsClearSelectionConfirmationDisplayed(false)}
          confirmFunction={handleClearSelection}
        />
      )}
    </>
  );
};

export default ExamRulesOverview;
