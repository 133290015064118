import React, { useContext } from "react";
import "./GradesSection.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendar,
  faChevronRight,
  faLineChart,
  faStethoscope,
} from "@fortawesome/free-solid-svg-icons";
import InformationCard from "../Components/InformationCard/InformationCard";
import { OSCEAdminContext } from "../..";
import formatDateString from "../../../../Functions/FormatDateString";
import getCategoryIconFromString from "../../Util/getCategoryIconFromString";

const GradesSection = ({ student }) => {
  const { handleChangePage } = useContext(OSCEAdminContext);

  const handleStudentGradesView = () => {
    const studentId = student.CustomerId;
    const studentName = `${student.CustomerFirstName} ${student.CustomerMiddleName} ${student.CustomerLastName}`;

    handleChangePage("Student Grades", {
      studentId: studentId,
      backButtonText: `Back to ${studentName}`,
      prevPage: "Student",
    });
  };

  if (!student.StudentGrades) {
    return null;
  }

  const { completedExams, casesCompletedCount } = student.StudentGrades;

  if (!completedExams) {
    return null;
  }

  const averageExamGrade = (
    completedExams.reduce((total, exam) => total + exam.OverallGrade, 0) /
    completedExams.length
  ).toFixed(2);

  return (
    <div className="studentProfilePageGradesSectionWrapper">
      <div className="studentProfilePageSectionHeaderWrapper">
        <h2 className="studentProfilePageSectionHeader">Grades</h2>
      </div>
      <div className="studentProfilePageGradesInfoCardsWrapper">
        <InformationCard title="Exams Completed" text={completedExams.length} />
        <InformationCard title="Cases Completed" text={casesCompletedCount} />
        <InformationCard title="Avg Exam Grade" text={`${averageExamGrade}%`} />
      </div>
      <div className="studentProfilePageGradesDivider" />
      {student.StudentGrades.completedExams.map((exam, index) => (
        <div
          className="studentProfilePageGradesCardContainer"
          onClick={handleStudentGradesView}
          key={exam.OsceGuid}
        >
          <div className="studentProfilePageGradesExamNumberContainer">
            <h3 className="studentProfilePageGradesExamNumber">{index + 1}</h3>
          </div>
          <div className="studentProfilePageGradesInfoContainer">
            <FontAwesomeIcon icon={faCalendar} />
            <h3 className="studentProfilePageGradesInfoTitle">
              Completion Date
            </h3>
            <p className="studentProfilePageGradesInfoText">
              {formatDateString(exam.TimeOsceEnded)}
            </p>
          </div>
          <div className="studentProfilePageGradesInfoContainer">
            <FontAwesomeIcon icon={faLineChart} />
            <h3 className="studentProfilePageGradesInfoTitle">Exam Grade</h3>
            <p className="studentProfilePageGradesInfoText">
              {exam.OverallGrade.toFixed(2)}%
            </p>
          </div>
          <div className="studentProfilePageGradesCasesWrapper">
            {exam.OsceIndGrade.map((caseDetails) => (
              <div
                className="studentProfilePageGradesCasesItem"
                key={caseDetails.LastAttemptDate}
              >
                <FontAwesomeIcon
                  icon={getCategoryIconFromString(caseDetails.CaseCategory)}
                />
                <p className="studentProfilePageGradesCasesText">
                  Case {caseDetails.CaseId} - {caseDetails.Diagnosis}
                </p>
              </div>
            ))}
          </div>
          <button className="studentProfilePageGradesViewButton">
            <FontAwesomeIcon icon={faChevronRight} />
          </button>
        </div>
      ))}
    </div>
  );
};

export default GradesSection;
