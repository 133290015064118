import React, { useState } from "react";
import {
  faChevronRight,
  faEllipsisVertical,
  faMagnifyingGlass,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ButtonListPopup from "../AdminPortal/Components/ButtonListPopup/ButtonListPopup";
import { createPortal } from "react-dom";

const PreceptorPortalHome = ({ userData, mousePosition, handleChangePage }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [groupSelected, setGroupSelected] = useState("All Groups");
  const [showButtonListPopup, setShowButtonListPopup] = useState(false);

  const getNoStudentsFoundText = () => {
    let noStudentsFoundText = "No students found";

    if (searchTerm) {
      noStudentsFoundText += ` for search term "${searchTerm}"`;
    }
    if (groupSelected !== "All Groups") {
      noStudentsFoundText += ` in group "${groupSelected} - ${
        userData.Groups.find((group) => group.groupId === Number(groupSelected))
          .GroupName
      }"`;
    }

    return noStudentsFoundText;
  };

  const handleStudentSelect = (student) => {
    handleChangePage("individualStudentGrades", {
      studentId: student.CustomerId,
      prevPage: "home",
      initialTab: null,
      backButtonText: "Back to Home",
    });
  };

  const handleMobileGroupSelect = (groupId) => {
    setGroupSelected(groupId);
    setShowButtonListPopup(false);
  };

  if (!userData.Students) {
    return (
      <div className="preceptorPortalNoStudentsWrapper">
        <p>
          No students currently assigned. Please contact your school
          administrator to have them assign your students to your account.
        </p>
      </div>
    );
  }

  const filteredStudents = userData.Students.filter((student) => {
    const studentName = `${student.CustomerFirstName} ${student.CustomerMiddleName} ${student.CustomerLastName}`;
    if (groupSelected === "All Groups") {
      return studentName.toLowerCase().includes(searchTerm.toLowerCase());
    } else {
      return (
        studentName.toLowerCase().includes(searchTerm.toLowerCase()) &&
        student.GroupId === Number(groupSelected)
      );
    }
  });

  return (
    <div className="preceptorHomeWrapper">
      <div className="preceptorPortalTableHeader">
        <h1>
          Students For {userData.CustomerFirstName} {userData.CustomerLastName}
        </h1>
        <div className="adminPortalTableHeaderControlWrapper">
          <div className="adminPortalTableHeaderSearchContainer">
            <FontAwesomeIcon icon={faMagnifyingGlass} />
            <input
              placeholder="Search The Student Table"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <select
            onChange={(e) => setGroupSelected(e.target.value)}
            className="adminPortalTableHeaderSelect"
            value={groupSelected}
          >
            <option value="All Groups">View All Student Groups</option>
            {userData.Groups.map((group) => (
              <option
                key={`${group.groupId} - ${group.GroupName}`}
                value={group.groupId}
              >
                {group.groupId}
                {" - "}
                {group.GroupName}
              </option>
            ))}
          </select>
          <button onClick={() => setShowButtonListPopup(true)}>
            <FontAwesomeIcon icon={faEllipsisVertical} />
          </button>
        </div>
      </div>
      {filteredStudents.map((student, index) => (
        <div
          className="preceptorPortalTableElementContainer"
          key={`${student.UniqueSignInId} - ${index}`}
          onClick={() => handleStudentSelect(student)}
        >
          <h2>
            {student.CustomerFirstName} {student.CustomerMiddleName}{" "}
            {student.CustomerLastName}
          </h2>
          <p>
            {student.CustomerEmail || "No Email Provided"}
            {student.GroupName ? ` | ${student.GroupName}` : ""}
          </p>
          <button>
            <FontAwesomeIcon icon={faChevronRight} />
          </button>
        </div>
      ))}
      {filteredStudents.length === 0 && (
        <div style={{ width: "80%", maxWidth: 300, margin: "auto" }}>
          <p style={{ textAlign: "center", fontWeight: "bold" }}>
            {getNoStudentsFoundText()}
          </p>
        </div>
      )}
      {showButtonListPopup &&
        createPortal(
          <ButtonListPopup
            showButtonListPopup={showButtonListPopup}
            setShowButtonListPopup={setShowButtonListPopup}
            mousePos={{
              x: mousePosition.x,
              y: mousePosition.y,
            }}
          >
            <select
              onChange={(e) => handleMobileGroupSelect(e.target.value)}
              className="examRulesCasesHeaderSelectMobilePopup"
              value={groupSelected}
            >
              <option value="All Groups">View All Student Groups</option>
              {userData.Groups.map((group) => (
                <option
                  key={`${group.groupId} - ${group.GroupName}`}
                  value={group.groupId}
                >
                  {group.groupId}
                  {" - "}
                  {group.GroupName}
                </option>
              ))}
            </select>
          </ButtonListPopup>,
          document.body
        )}
    </div>
  );
};

export default PreceptorPortalHome;
