import React, { useEffect, useState } from "react";
import "./LoginPage.css";
import logFetchError from "../Functions/LogFetchError";
import Logo from "../images/logoCropped.png";
import LandingImage from "../images/Doctor.png";
import CCSCasesLogin from "./CCSCasesLogin";
import OSCELogin from "../OSCE/Login/OSCELogin";

const LoginPage = ({
  selectedTheme,
  route,
  primaryColor,
  handleLogin,
  application,
}) => {
  const [systemMessage, setSystemMessage] = useState("");

  useEffect(() => {
    fetchSystemMessage();

    if (selectedTheme) {
      var metaThemeColor = document.querySelector("meta[name=theme-color]");
      metaThemeColor.setAttribute("content", selectedTheme.primaryColor);
    }
  });

  const fetchSystemMessage = () => {
    let systemMessage = "";
    if (process.env.REACT_APP_ENABLE_LANDING_MESSAGE === "true") {
      fetch(`${route}/announcementnew.webapi`, {
        method: "GET",
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
        })
        .then((responseMessage) => {
          if (responseMessage.Announcement !== "") {
            setSystemMessage(responseMessage.Announcement);
          }
        })
        .catch((error) => {
          logFetchError(error, null, null, "fetchSystemMessage");
        });
    }
  };

  let loginPageForm = null;

  switch (application) {
    case "OSCE":
      loginPageForm = <OSCELogin handleLogin={handleLogin} route={route} />;
      break;
    default:
      loginPageForm = (
        <CCSCasesLogin
          route={route}
          primaryColor={primaryColor}
          handleLogin={handleLogin}
        />
      );
      break;
  }

  return (
    <div className="loginPageWrapper">
      <div className="loginLandingPage">
        <img src={Logo} className="Logo loginLogo" stroke="none" />
        <div className="loginCardContainer">
          <img className="loginImage" src={LandingImage} />
          {loginPageForm}
          {systemMessage !== "" && (
            <div className="loginMessageFooter">
              <p className="loginMessageFooterText">{systemMessage}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
