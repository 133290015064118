import DonutChart from "../../DonutChart/DonutChart";

const ExamTimerDonutChart = ({ initialTime, timePassed }) => {
  return (
    <DonutChart
      id={"TestDonutChart"}
      data={{
        datasets: [
          {
            data: [initialTime - timePassed, timePassed],
            backgroundColor: ["#0b335d", "white"],
            borderWidth: 0,
            spacing: 0,
          },
        ],
      }}
      options={{
        title: {
          display: false,
        },
        legend: {
          display: false,
        },
        cutout: 20,
        animation: {
          animateScale: true,
        },
        events: [],
      }}
    />
  );
};

export default ExamTimerDonutChart;
